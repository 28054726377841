import { PropTypes, shape, arrayOf } from "prop-types";

export const magazine = shape({
  explosives_permit_magazine_id: PropTypes.number,
  explosives_permit_id: PropTypes.number,
  explosives_permit_magazine_type_code: PropTypes.string,
  type_no: PropTypes.string,
  tag_no: PropTypes.string,
  construction: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  length: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  quantity: PropTypes.number,
  distance_road: PropTypes.number,
  distance_dwelling: PropTypes.number,
  detonator_type: PropTypes.string,
});

export const explosivesPermit = shape({
  explosives_permit_id: PropTypes.number,
  explosives_permit_guid: PropTypes.string,
  mine_guid: PropTypes.string,
  permit_guid: PropTypes.string,
  now_application_guid: PropTypes.string,
  issuing_inspector_party_guid: PropTypes.string,
  issuing_inspector_name: PropTypes.string,
  permittee_mine_party_appt_id: PropTypes.number,
  permittee_name: PropTypes.string,
  mine_manager_mine_party_appt_id: PropTypes.number,
  mine_manager_name: PropTypes.string,
  application_status: PropTypes.string,
  permit_number: PropTypes.string,
  issue_date: PropTypes.string,
  expiry_date: PropTypes.string,
  application_number: PropTypes.string,
  application_date: PropTypes.string,
  originating_system: PropTypes.string,
  received_timestamp: PropTypes.string,
  decision_timestamp: PropTypes.string,
  decision_reason: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  is_closed: PropTypes.bool,
  closed_timestamp: PropTypes.string,
  closed_reason: PropTypes.string,
  total_detonator_quantity: PropTypes.number,
  total_explosive_quantity: PropTypes.number,
  description: PropTypes.string,
  explosive_magazines: arrayOf(magazine),
  detonator_magazines: arrayOf(magazine),
  documents: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  mines_permit_number: PropTypes.string,
  now_number: PropTypes.string,
});
