// Redux Forms
export const UPDATE_MINE_REPORT_SUBMISSION_STATUS = "UPDATE_MINE_REPORT_SUBMISSION_STATUS";
export const ADD_FULL_PARTY = "ADD_FULL_PARTY";
export const ADD_ROLES = "ADD_ROLES";
export const ADD_QUICK_PARTY = "ADD_QUICK_PARTY";
export const MERGE_PARTY_CONFIRMATION = "MERGE_PARTY_CONFIRMATION";
export const ADD_TENURE_NUMBER = "ADD_TENURE_NUMBER";
export const MINE_RECORD = "MINE_RECORD";
export const SEARCH_COORDINATES = "SEARCH_COORDINATES";
export const UPDATE_PERMITTEE = "UPDATE_PERMITTEE";
export const ADD_TAILINGS = "ADD_TAILINGS";
export const ADD_TAILINGS_REPORT = "ADD_TAILINGS_REPORT";
export const EDIT_TAILINGS = "EDIT_TAILINGS";
export const ADD_PARTY_RELATIONSHIP = "ADD_PARTY_RELATIONSHIP";
export const EDIT_PARTY_RELATIONSHIP = "EDIT_PARTY_RELATIONSHIP";
export const MINE_ADVANCED_SEARCH = "MINE_ADVANCED_SEARCH";
export const CONTACT_ADVANCED_SEARCH = "CONTACT_ADVANCE_SEARCH";
export const VARIANCE_ADVANCED_SEARCH = "VARIANCE_ADVANCED_SEARCH";
export const INCIDENT_ADVANCED_SEARCH = "INCIDENT_ADVANCED_SEARCH";
export const REPORT_ADVANCED_SEARCH = "REPORT_ADVANCED_SEARCH";
export const MAJOR_MINE_APPLICATION_ADVANCED_SEARCH = "MAJOR_MINE_APPLICATION_ADVANCED_SEARCH";
export const ADD_MINESPACE_USER = "ADD_MINESPACE_USER";
export const EDIT_MINESPACE_USER = "EDIT_MINESPACE_USER";
export const ADD_PERMIT = "ADD_PERMIT";
export const UPLOAD_PERMIT_DOCUMENT = "UPLOAD_PERMIT_DOCUMENT";
export const PERMIT_AMENDMENT = "PERMIT_AMENDMENT";
export const EDIT_PERMIT = "EDIT_PERMIT";
export const EDIT_FULL_PARTY = "EDIT_FULL_PARTY";
export const MINE_COMPLIANCE_FILTER = "MINE_COMPLIANCE_FILTER";
export const ADD_VARIANCE = "ADD_VARIANCE";
export const EDIT_VARIANCE = "EDIT_VARIANCE";
export const MINE_INCIDENT = "MINE_INCIDENT";
export const ADD_EDIT_INCIDENT = "ADD_EDIT_INCIDENT";
export const ADD_REPORT = "ADD_REPORT";
export const EDIT_REPORT = "EDIT_REPORT";
export const FILTER_REPORTS = "FILTER_REPORTS";
export const GENERATE_DOCUMENT = "GENERATE_DOCUMENT";
export const EDIT_SITE_PROPERTIES = "EDIT_SITE_PROPERTIES";
export const ADD_MINE_WORK_INFORMATION = "ADD_MINE_WORK_INFORMATION";
export const EXPLOSIVES_PERMIT = "EXPLOSIVES_PERMIT";
export const EXPLOSIVES_PERMIT_NEW = "EXPLOSIVES_PERMIT_NEW";
export const EDIT_EXPLOSIVES_PERMIT_STATUS = "EDIT_EXPLOSIVES_PERMIT_STATUS";
export const EXPLOSIVES_PERMIT_DECISION = "EXPLOSIVES_PERMIT_DECISION";
export const EXPLOSIVES_PERMIT_CLOSE = "EXPLOSIVES_PERMIT_CLOSE";

// Notice Of Work
export const NOTICE_OF_WORK_SEARCH = "NOTICE_OF_WORK_SEARCH";
export const EDIT_NOTICE_OF_WORK = "EDIT_NOTICE_OF_WORK";
export const EDIT_NOTICE_OF_WORK_DOCUMENT_FORM = "EDIT_NOTICE_OF_WORK_DOCUMENT_FORM";
export const CHANGE_NOW_MINE = "CHANGE_NOW_MINE";
export const UPDATE_NOW_LEAD_INSPECTOR = "UPDATE_NOW_LEAD_INSPECTOR";
export const UPDATE_NOW_STATUS = "UPDATE_NOW_STATUS";
export const ADD_NOW_REVIEW = "ADD_NOW_REVIEW";
export const MM_PERMIT_APPLICATION_CREATE = "MM_PERMIT_APPLICATION_CREATE";
export const CHANGE_NOW_LOCATION = "CHANGE_NOW_LOCATION";
export const MAJOR_MINE_PERMIT_APPLICATION_CREATE = "MAJOR_MINE_PERMIT_APPLICATION_CREATE";
export const NOW_CONTACT_FORM = "NOW_CONTACT_FORM";
export const VERIFY_NOW_APPLICATION_FORM = "VERIFY_NOW_APPLICATION_FORM";
export const MANAGE_DELAY_FORM = "MANAGE_DELAY_FORM";
export const UPDATE_PROGRESS_DATE_FORM = "UPDATE_PROGRESS_DATE_FORM";

// Applications
export const ADMINISTRATIVE_AMENDMENT_FORM = "ADMINISTRATIVE_AMENDMENT_FORM";

// Securities
export const ADD_BOND = "ADD_BOND";
export const TRANSFER_BOND = "TRANSFER_BOND";
export const CLOSE_BOND = "CLOSE_BOND";
export const ADD_RECLAMATION_INVOICE = "ADD_RECLAMATION_INVOICE";

// Permit Generation
export const GENERATE_PERMIT = "GENERATE_PERMIT";
export const PRE_DRAFT_PERMIT = "PRE_DRAFT_PERMIT";
export const CONDITION_SECTION = "CONDITION_SECTION";

// Process Permit
export const ISSUE_PERMIT = "ISSUE_PERMIT";
export const REJECT_APPLICATION = "REJECT_APPLICATION";
export const GENERATE_PERMIT_NUMBER = "GENERATE_PERMIT_NUMBER";

// EMLI Contacts
export const EMLI_CONTACT_FORM = "EMLI_CONTACT_FORM";

// Project Descriptions
export const PROJECT_SUMMARY = "PROJECT_SUMMARY";
export const ADD_EDIT_PROJECT_SUMMARY = "ADD_EDIT_PROJECT_SUMMARY";

// Information Requirements Table
export const UPDATE_INFORMATION_REQUIREMENTS_TABLE = "UPDATE_INFORMATION_REQUIREMENTS_TABLE";

// Major Mine Application
export const UPDATE_MAJOR_MINE_APPLICATION = "UPDATE_MAJOR_MINE_APPLICATION";

// Permit Package
export const UPDATE_PROJECT_DECISION_PACKAGE = "UPDATE_PROJECT_DECISION_PACKAGE";
export const UPDATE_PROJECT_DECISION_PACKAGE_DOCUMENT = "UPDATE_PROJECT_DECISION_PACKAGE_DOCUMENT";

// Notice of Departure
export const NOTICE_OF_DEPARTURE_FORM = "NOTICE_OF_DEPARTURE_FORM";

// Tailings
export const ADD_STORAGE_FACILITY = "ADD_STORAGE_FACILITY";

// Dams
export const ADD_EDIT_DAM = "ADD_EDIT_DAM";

// Staff Alerts
export const ADD_EDIT_MINE_ALERT = "ADD_EDIT_MINE_ALERT";

// Compliance Codes
export const COMPLIANCE_CODE_BULK_EDIT = "COMPLIANCE_CODE_BULK_EDIT";
export const ADD_COMPLIANCE_CODE = "ADD_COMPLIANCE_CODE";
